import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from '../../store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAuthData, ICredentials, IUser } from '../../models/user';
import { ApiService } from '../api/api.service';
import { loadCurrentUser, signInSuccess } from 'src/app/store/auth/auth.actions';
import { ACCESS_TOKEN_KEY } from 'src/app/common-ts/constants';
import { websocketStartConnect } from 'src/app/store/websocket/websocket.actions';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private readonly registerUrl = 'auth/register';
    private readonly resetPasswordUrl = 'auth/reset/password';
    private readonly confirmRegisterEmailUrl = 'auth/confirmation/email';
    private readonly singInUrl = 'auth/login';
    private readonly profileUrl = 'user/me';
    private readonly updateProfileUrl = 'user/me';
    private readonly authProfileUrl = 'auth/profile';
    

    constructor(
        private apiService: ApiService,
        private store: Store<State>,
        private router: Router
    ) {}

    static saveToken(token: string): void {
        localStorage.setItem(ACCESS_TOKEN_KEY, token);
    }

    static deleteToken(): void {
        localStorage.removeItem(ACCESS_TOKEN_KEY);
    }

    static getSavedToken(): string | null {
        try {
            return localStorage.getItem(ACCESS_TOKEN_KEY) || null;
        } catch (err) {
            return null;
        }
    }

    confirmRegisterEmail(email: string): Observable<string> {
        return this.apiService.postRequest(this.confirmRegisterEmailUrl, { email })
            .pipe(map(resp => resp?.data));
    }

    checkCurrentUser(): Observable<IUser> {
        return this.apiService.getRequest(this.profileUrl)
            .pipe(map(resp => resp?.data));
    }

    redirect(redirectTo?: string, params?: any): void {
        this.router.navigate([redirectTo || '/'], params && { queryParams: params});
    }

    signIn(credentials: Partial<ICredentials>): Observable<IAuthData> {
        return this.apiService.postRequest(this.singInUrl, credentials)
            .pipe(map(resp => resp?.data));
    }

    signInWithSocialLinks(credentials: any): Observable<IAuthData> {
        return this.apiService.postRequest('auth/3rd', credentials)
            .pipe(map(resp => resp?.data));
    }

    registration(credentials: ICredentials): Observable<IAuthData> {
        return this.apiService.postRequest(this.registerUrl, credentials)
            .pipe(map(resp => resp?.data));
    }

    updateOwnProfile(params: Partial<IUser>): Observable<IUser> {
        return this.apiService.patchRequest(this.updateProfileUrl, params)
            .pipe(map(resp => resp?.data));
    }

    updateNotification(params: Partial<IUser>): Observable<IUser> {
      return this.apiService.patchRequest(this.authProfileUrl, params ).pipe(map(resp => resp?.data));
    }

    resetPassword({ email }: { email: string }): Observable<boolean> {
        return this.apiService.postRequest(this.resetPasswordUrl, { email });
    }

    checkUserData(): void {
        const savedToken = AuthService.getSavedToken();

        if (savedToken) {
            this.store.dispatch(signInSuccess({authData: { accessToken: savedToken }}));
            this.store.dispatch(loadCurrentUser());
            this.store.dispatch(websocketStartConnect({token: savedToken}));
        }
    }
}
