import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, exhaustMap, map, switchMap } from 'rxjs/operators';
import { forkJoin, of, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '../../store';

import * as AuthActions from './auth.actions';
import { AuthService } from 'src/app/services/auth/auth.service';
import { IUser } from 'src/app/models/user';
import { MatDialog } from '@angular/material/dialog';
import { InfoDialogComponent } from 'src/app/shared/shared-modules/dialogs/info-dialog/info-dialog.component';
import { getResponseErrorMessage } from 'src/app/common-ts/error-helpers';
import { HttpErrorResponse } from '@angular/common/http';
import { UsersService } from 'src/app/services/users/users.service';
import { websocketDisconnect, websocketStartConnect } from '../websocket/websocket.actions';
import { loadNewMessageCounterSuccess } from '../chatting/chatting.actions';
import {loadNewNotificationCounterSuccess} from '../notification/notification.actions'

@Injectable()
export class AuthEffects {
    signIn$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActions.signIn),
            exhaustMap(({ credentials, eventId, invitationToken }) => {
                return this.authService.signIn(credentials).pipe(
                    map((authData) => {
                        const token = authData.accessToken;
                        AuthService.saveToken(token);
                        this.store.dispatch(websocketStartConnect({ token }));
                        const role = authData?.user?.role?.toLowerCase();
                        const redirectTo = eventId
                            ? `/artist/event/${eventId}`
                            : role === 'super_admin' ? 'admin' : role;
                        this.authService.redirect(redirectTo, invitationToken && { invitationToken });

                        return AuthActions.signInSuccess({ authData });
                    }),
                    catchError((error: HttpErrorResponse) => {
                        this.getErrorAlert(error);
                        return of(AuthActions.signInFailure({ error }));
                    }),
                );
            }),
        );
    });

    registration$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActions.registration),
            exhaustMap(({ credentials, invitationToken, eventId }) => {
                return this.authService.registration(credentials).pipe(
                    map((authData) => {
                        AuthService.saveToken(authData.accessToken);
                        const redirectTo = invitationToken ? `/artist/event/${eventId}` : '/profile/main';
                        this.authService.redirect(redirectTo, invitationToken ? { invitationToken, eventId } : { isCreateMode: true });
                        return AuthActions.signInSuccess({ authData });
                    }),
                    catchError((error: HttpErrorResponse) => {
                        this.getErrorAlert(error);
                        return of(AuthActions.registrationFailure({ error }));
                    }),
                );
            }),
        );
    });

    signInSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActions.signInSuccess),
            map(() => AuthActions.loadCurrentUser()),
            catchError((error: HttpErrorResponse) => of(AuthActions.loadCurrentUserFailure({ error }))),
        );
    });

    signOut$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActions.signOut),
            map(({withRedirect}) => {
                AuthService.deleteToken();
                this.store.dispatch(websocketDisconnect());             
                if (withRedirect) {
                    this.authService.redirect('/auth/login');
                }
                return AuthActions.signOutSuccess();
            }),
            catchError((error: HttpErrorResponse) => of(AuthActions.signOutFailure({ error }))),
        );
    });

    loadCurrentUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActions.loadCurrentUser),
            concatMap(() => this.authService.checkCurrentUser()
                .pipe(
                    switchMap((user: IUser) => forkJoin([this.usersService.getUserStats(user.id), of(user)])
                        .pipe(catchError((error) => throwError({error})))),
                    exhaustMap(([userStats, user]) => [
                        AuthActions.loadCurrentUserSuccess({ user: {...user, ...userStats} }),
                        loadNewMessageCounterSuccess({ count: userStats?.newMessagesCount || 0 }),
                        loadNewNotificationCounterSuccess({ newNotificationsCount: userStats?.newNotificationsCount || 0 }),
                    ]),
                    catchError((error: HttpErrorResponse) => of(AuthActions.loadCurrentUserFailure({ error })))
                ))
        );
    });

    updateCurrentUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActions.updateCurrentUser),
            concatMap(({params}) => this.authService.updateOwnProfile(params)
                .pipe(
                    map((user) => AuthActions.updateCurrentUserSuccess({ user })),
                    catchError((error: HttpErrorResponse) => {
                        this.getErrorAlert(error);
                        return of(AuthActions.updateCurrentUserFailure({ error }));
                    })
                ))
        );
    });

    constructor(
        private actions$: Actions,
        private authService: AuthService,
        private usersService: UsersService,
        private matDialog: MatDialog,
        private store: Store<State>
    ) {}

    getErrorAlert(error: HttpErrorResponse): void {
        const err = error?.error?.errors;
        if(!err){
            return;
        }
        if (error.error.errors[0].message === 'AUTH_INVALID_CONFIRM_TOKEN'){
            return;
        }
        else if (error.error.errors[0].message === 'USER_BLOCKED' || error.error.errors[0].message === 'USER_DELETED') {
            let message = error.error.errors[0].message === 'USER_BLOCKED' ? 'Your profile was blocked by the Administrator. Contact us' :
            'Your profile was deactivated, for activation Contact us'
            this.matDialog.open(InfoDialogComponent, {
                width: '571px',
                backdropClass: 'backdrop__dark',
                panelClass: 'info-dialog',
                data: {
                    icon: { name: 'alert' },
                    title: 'Ooops!',
                    message,
                    link: 'mailto:customers@spectaculum.live',
                    linkTitle:'Help & Support'
             },
         });
        } else {
            this.matDialog.open(InfoDialogComponent, {
                width: '571px',
                backdropClass: 'backdrop__dark',
                panelClass: 'info-dialog',
                data: {
                    icon: { name: 'alert' },
                    title: 'Ooops!',
                    message: getResponseErrorMessage(error),
                },
            });
        }
    }
}
