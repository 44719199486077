/* eslint-disable indent */
import { HttpErrorResponse } from "@angular/common/http";
import { AbstractControl } from "@angular/forms";

export function getResponseErrorMessage(errorResp: HttpErrorResponse): string {
    const errArray = errorResp.error?.errors;
    let errorMessage = '';

    if (Array.isArray(errArray) && errArray.length) {
        errArray.forEach(el => {
            const constraints = el.constraints;
            if (constraints) {
                if (constraints.includes('price must not be less than 1')) {
                    errorMessage = 'The cost of the event must be more than $ 1';
                } else if (constraints.includes('imageId must be a UUID')) {
                    errorMessage = 'Image is required for any event';
                } else if (constraints.includes('confirmPassword must be equal newPassword')) {
                    errorMessage = 'The confirmed password does not match the new password. Check it out.';
                } else {
                    errorMessage = constraints.join('\n');
                }
            } else {
                switch(el.message) {
                    case 'Unauthorized':
                        errorMessage += 'Unauthorized\n';
                        break;

                    case 'USER_WITH_EMAIL_DOES_NOT_EXIST':
                        errorMessage += 'User with this email does not exist\n';
                        break;

                    case 'USER_WITH_EMAIL_ALREADY_EXISTS':
                        errorMessage += 'User with this email already exist\n';
                        break;
    
                    case 'USER_WITH_PHONE_ALREADY_EXISTS':
                        errorMessage += 'User with this phone already exist\n';
                        break;
    
                    case 'INVALID_PASSWORD':
                        errorMessage += 'Password is wrong\n';
                        break;

                    case 'WORKDAY_RANGE_MUST_BE_UNIQUE':
                        errorMessage += 'A workday range must be unique\n';
                        break;

                    case 'WORKDAY_RANGE_NOT_FOUND':
                        errorMessage += 'The workday range not found\n';
                        break;

                    case 'WORKDAY_RANGE_ALREADY_EXISTS':
                        errorMessage += 'The workday range already exists\n';
                        break;

                    case 'WORKDAY_RANGE_TIME_SLOT_NOT_FOUND':
                        errorMessage += 'The workday range time slot not found\n';
                        break;

                    case 'USER_ALREADY_LEAVE_FEEDBACK':
                        errorMessage += 'You have already left a feedback about this event\n';
                        break;

                    case 'EVENT_BOOKING_START_IS_PAST_DATE':
                        errorMessage += 'Event booking start is past date\n';
                        break;

                    case 'USER_DELETED':
                        errorMessage += 'This account has been deleted\n';
                        break;

                    case 'PROMOCODE_ALREADY_EXIST':
                        errorMessage += 'This promocode already exist\n';
                        break;
                    case 'EVENT_ALREADY_EXISTS_ON_CHOOSEN_TIME':
                        errorMessage += 'You have already had the event at this time!\n';
                        break;
                    case 'CHARGEBEE_UPDATE_PAYMENT_DATA':
                    case 'CHARGEBEE_PAYMENT_DATA_IS_NOT_VALID':
                        errorMessage += 'The payment data is not valid.\n';
                        break;
                    case 'EVENT_BOOKING_ALREADY_EXISTS_ON_CHOOSEN_TIME':
                        errorMessage += 'This time has just been booked by someone else.\n';
                        break;
                }
            }
        });
    }

    return errorMessage || 'Something went wrong';
}

export function getFieldErrorMessage(formControl: AbstractControl, field = 'field'): string {
    return formControl?.hasError('required') && 'This field is required'
        || formControl?.hasError('pattern') && (field === 'password' ? 'Password should contain one capital letter, one number' : `Please enter correct ${field}`) // to do password message
        || formControl?.hasError('minlength') && `${field} must contain at least ${formControl.getError('minlength').requiredLength} symbols`
        || formControl?.hasError('maxlength') && `${field} must contain max ${formControl.getError('maxlength').requiredLength} symbols`
        || formControl?.hasError('mismatch') && 'Passwords do not match'
        || formControl?.hasError('confirmCode') && 'Confirmation code is incorrect'
        || formControl?.hasError('max') && `field data is invalid`
        || '';
}
