import { environment } from '../../../src/environments/environment';
import { ICategoryCard } from '../models/categories';
import { IDropdownItem } from '../models/dropdowns';
import { IPromotion } from '../models/promotions';
import { IRadioItem } from '../models/radio-button';

export const DEV_API_URL = environment.api;
// export const DEV_API_URL = 'http://localhost:8080/api/';

export const PROD_API_URL = DEV_API_URL;

export const STREAMING_API_URL = environment.streaming;

export const WEB_SOCKET_URL = environment.websocket;
// export const WEB_SOCKET_URL = 'ws://localhost:8080/';

export const ACCESS_TOKEN_KEY = 'token';

export const countOfGuests = 20;

export const startDateForNewUsers = new Date('February 1, 2022');

export const FEEDBEACK_TROUBLES: { [key: string]: string[] } = {
    artist: [
        'Privacy violation',
        'Pornographic content',
        'Abuse',
        'Hate Speech',
        'Extremist content',
    ],
    audio: [
        'I couldn\'t hear the interlocutor',
        'The sound was interrupted',
        'The sound was transmitted with a delay',
        'An echo was observed',
        'The interlocutor did not hear me',
        'Other sound problems',
    ],
    video: [
        'Interlocutor audio and video were out of sync',
        'I couldn\'t see the interlocutor',
        'My camera was not working',
        'The video was blurry',
        'Video broadcast was interrupted',
        'Other video problems',
    ],
};

// lists

export const WEBSITE__LANGUAGES_LIST: IDropdownItem[] = [
    {
        id: '101',
        title: 'En',
        query: 'en',
    },
    // {
    //     id: '102',
    //     title: 'Ar',
    //     query: 'arabic',
    // },
    {
        id: '103',
        title: 'He',
        query: 'he',
    },
];

export const USER_LANGUAGES_LIST: IDropdownItem[] = [
    {
        id: 'ar',
        title: 'Arab',
        query: 'ar',
        type: 'languageAr',
        state: false,
        selected: false,
    },
    {
        id: 'bn',
        title: 'Bengali',
        query: 'bn',
        type: 'languageBn',
        state: false,
        selected: false,
    },
    {
        id: 'zh',
        title: 'Chinese',
        query: 'zh',
        type: 'languageZh',
        state: false,
        selected: false,
    },
    {
        id: 'en',
        title: 'English',
        query: 'en',
        type: 'languageEn',
        state: false,
        selected: false,
    },
    {
        id: 'fr',
        title: 'French',
        query: 'fr',
        type: 'languageFr',
        state: false,
        selected: false,
    },
    {
        id: 'de',
        title: 'German',
        query: 'de',
        type: 'languageGe',
        state: false,
        selected: false,
    },
    {
        id: 'he',
        title: 'Hebrew',
        query: 'he',
        type: 'languageHe',
        state: false,
        selected: false,
    },
    {
        id: 'hi',
        title: 'Hindi',
        query: 'hi',
        type: 'languageHi',
        state: false,
        selected: false,
    },
    {
        id: 'it',
        title: 'Italian',
        query: 'it',
        type: 'languageIt',
        state: false,
        selected: false,
    },
    {
        id: 'ja',
        title: 'Japanese',
        query: 'ja',
        type: 'languageJa',
        state: false,
        selected: false,
    },
    {
        id: 'ko',
        title: 'Korean',
        query: 'ko',
        type: 'languageKo',
        state: false,
        selected: false,
    },
    {
        id: 'fa',
        title: 'Persian',
        query: 'fa',
        type: 'languageFa',
        state: false,
        selected: false,
    },
    {
        id: 'pt',
        title: 'Portuguese',
        query: 'pt',
        type: 'languagePt',
        state: false,
        selected: false,
    },
    {
        id: 'pl',
        title: 'Polish',
        query: 'pl',
        type: 'languagePl',
        state: false,
        selected: false,
    },
    {
        id: 'ro',
        title: 'Romanian',
        query: 'ro',
        type: 'languageRo',
        state: false,
        selected: false,
    },
    {
        id: 'ru',
        title: 'Russian',
        query: 'ru',
        type: 'languageRu',
        state: false,
        selected: false,
    },
    {
        id: 'es',
        title: 'Spanish',
        query: 'es',
        type: 'languageEs',
        state: false,
        selected: false,
    },
    {
        id: 'ta',
        title: 'Tamil',
        query: 'ta',
        type: 'languageTa',
        state: false,
        selected: false,
    },

    {
        id: 'tr',
        title: 'Turkish',
        query: 'tr',
        type: 'languageTr',
        state: false,
        selected: false,
    },
    {
        id: 'vi',
        title: 'Vietnamese',
        query: 'vi',
        type: 'languageVi',
        state: false,
        selected: false,
    },
];

export const USER_COUNTRY_LIST: IDropdownItem[] = [
    {
        id: 'CA',
        title: 'Canada',
        type: 'countryCA',
        state: false,
    },
    {
        id: 'FR',
        title: 'France',
        query: 'FR',
        type: 'countryFR',
        state: false,
    },
    {
        id: 'DE',
        title: 'Germany',
        type: 'countryDE',
        state: false,
    },
    {
        id: 'IL',
        title: 'Israel',
        type: 'countryIL',
        state: false,
    },
    {
        id: 'IT',
        title: 'Italy',
        type: 'countryIT',
        state: false,
    },
    {
        id: 'RU',
        title: 'Russia',
        type: 'countryRU',
        state: false,
    },
    {
        id: 'ES',
        title: 'Spain',
        query: 'ES',
        type: 'countryES',
        state: false,
    },
    {
        id: 'US',
        title: 'United states',
        type: 'countryUS',
        state: false,
    },
    {
        id: 'Others',
        title: 'Others',
        type: 'countryOthers',
        state: false,
    },
];

export const ARTIST_AGENT_REASON_LIST: IDropdownItem[] = [
    {
        id: '1',
        title: 'Not interesting',
    },
    {
        id: '2',
        title: 'I will come back to the system later',
    },
    {
        id: '3',
        title: `I don't like the quality of streaming`,
    },
    {
        id: '3',
        title: 'Other',
    },
];

export const GUEST_REASON_LIST: IDropdownItem[] = [
    {
        id: '1',
        title: 'No time',
    },
    {
        id: '2',
        title: 'Not interested',
    },
    {
        id: '3',
        title: `No my genres`,
    },
    {
        id: '4',
        title: `No my favorite headings`,
    },
    {
        id: '5',
        title: `The service did not meet my expectations`,
    },
    {
        id: '6',
        title: 'Other',
    },
];

export const CURRENCY_LIST: IDropdownItem[] = [
    {
        id: '201',
        title: 'USD, $',
        query: 'usd',
         selected: false,
    },
    {
        id: '202',
        title: 'EUR, €',
        query: 'eur',
        selected: true,
    },
];

export const DISCOUNTY_LIST: IDropdownItem[] = [
    {
        id: '301',
        title: '10%',
        query: '10',
    },
    {
        id: '302',
        title: '25%',
        query: '25',
    },
    {
        id: '303',
        title: '50%',
        query: '50',
    },
];

// sorting

const BY_DATE: IDropdownItem[] = [
    {
        id: '400',
        title: 'By newer date',
        query: 'createdAt',
        sortDirection: 'desc',
    },
    {
        id: '401',
        title: 'By further date',
        query: 'createdAt',
        sortDirection: 'asc',
    },
];

const ARTISTS_POPULARITY: IDropdownItem = {
    id: '402',
    title: 'By popularity',
    query: 'subscribersCount',
    sortDirection: 'desc',
};

const EVENTS_POPULARITY: IDropdownItem = {
    id: '411',
    title: 'By popularity',
    query: 'boughtTickets',
    sortDirection: 'desc',
};

const RATE: IDropdownItem = {
    id: '403',
    title: 'By rating',
    query: 'rating',
    sortDirection: 'desc',
};

const ALPHABETICALLY_USERS: IDropdownItem[] = [
    {
        id: '405',
        title: 'Alphabetically (A-Z)',
        query: 'name',
        sortDirection: 'asc',
    },
    {
        id: '406',
        title: 'Alphabetically (Z-A)',
        query: 'name',
        sortDirection: 'desc',
    },
];

const ALPHABETICALLY_EVENTS: IDropdownItem[] = [
    {
        id: '415',
        title: 'Alphabetically (A-Z)',
        query: 'title',
        sortDirection: 'asc',
    },
    {
        id: '416',
        title: 'Alphabetically (Z-A)',
        query: 'title',
        sortDirection: 'desc',
    },
];

export const WITH_POPULARITY_USER_SORTING_LIST: IDropdownItem[] = [
    ...BY_DATE,
    ARTISTS_POPULARITY,
    ...ALPHABETICALLY_USERS,
];

export const WITH_VIEWERS_VIDEO_SORTING_LIST: IDropdownItem[] = [
    {
        id: '402',
        title: 'By popularity',
        query: 'viewed',
        sortDirection: 'desc',
    },
    ...BY_DATE,
    ...ALPHABETICALLY_USERS,
];

export const WITH_POPULARITY_EVENTS_SORTING_LIST: IDropdownItem[] = [
    {
        id: '400',
        title: 'By newer date',
        query: 'start',
        sortDirection: 'desc',
    },
    {
        id: '401',
        title: 'By further date',
        query: 'start',
        sortDirection: 'asc',
    },
    EVENTS_POPULARITY,
    ...ALPHABETICALLY_EVENTS,
];

export const WITH_RATE_SORTING_LIST: IDropdownItem[] = [...BY_DATE, RATE];

export const ARTISTS__SORTING__LIST: IDropdownItem[] = [
    ARTISTS_POPULARITY,
    RATE,
    ...ALPHABETICALLY_USERS,
];

export const ARTISTS__SORTING__LIST__WITH__NEWEST: IDropdownItem[] = [
    {
        id: '400',
        title: 'By newest',
        query: 'createdAt',
        sortDirection: 'desc',
    },
    ...ARTISTS__SORTING__LIST,
];

export const EVENTS_SORTING_LIST: IDropdownItem[] = [EVENTS_POPULARITY, ...ALPHABETICALLY_EVENTS];

export const MY_ARTISTS_SORTING_LIST: IDropdownItem[] = [
    {
        id: '407',
        title: 'Last added',
        query: 'createdAt',
        sortDirection: 'desc',
    },
    {
        id: '408',
        title: 'First added',
        query: 'createdAt',
        sortDirection: 'asc',
    },
    ...ALPHABETICALLY_USERS,
];

export const EVENTS_FILTER_LIST: IDropdownItem[] = [
    {
        id: '502',
        title: 'Private events',
        query: 'private',
    },
    {
        id: '503',
        title: 'Public events',
        query: 'public',
    },
];

export const FEEDBACKS_FILTER_LIST: IDropdownItem[] = [
    {
        id: '501',
        title: 'All feedbacks',
        query: 'all',
    },
    {
        id: '502',
        title: 'Private events',
        query: 'private',
    },
    {
        id: '503',
        title: 'Public events',
        query: 'public',
    },
];

export const CALENDAR_FILTER_LIST: IDropdownItem[] = [
    {
        id: '501',
        title: 'All artists',
        query: 'all',
    },
    {
        id: '502',
        title: 'With private events',
        query: 'private',
    },
    {
        id: '503',
        title: 'With public events',
        query: 'public',
    },
];

export const VIDEOS_FILTER_LIST: IDropdownItem[] = [
    {
        id: '500',
        title: 'All videos',
        query: 'all',
    },
    {
        id: '501',
        title: 'Free videos',
        query: 'free',
    },
    {
        id: '502',
        title: 'Paid videos',
        query: 'paid',
    },
];

export const ADMIN_BLOCKED_FILTER_LIST: IDropdownItem[] = [
    {
        id: '601',
        title: 'Blocked',
        value: true,
    },
    {
        id: '602',
        title: 'Active',
        value: false,
    },
];

export const ADMIN_ARITSTS_BLOCKED_FILTER_LIST: IDropdownItem[] = [
    {
        id: '600',
        title: 'All artists',
        value: null,
    },
    ...ADMIN_BLOCKED_FILTER_LIST,
];

export const ADMIN_GUESTS_BLOCKED_FILTER_LIST: IDropdownItem[] = [
    {
        id: '600',
        title: 'All guests',
        value: null,
    },
    ...ADMIN_BLOCKED_FILTER_LIST,
];

export const ADMIN_AGENTS_BLOCKED_FILTER_LIST: IDropdownItem[] = [
    {
        id: '600',
        title: 'All agents',
        value: null,
    },
    ...ADMIN_BLOCKED_FILTER_LIST,
];

export const ADMIN_DEVICE_TYPE_LIST: IDropdownItem[] = [
    {
        id: '700',
        title: 'All devices types',
        query: '',
    },
    {
        id: '701',
        title: 'Desktop devices',
        query: 'Desktop',
    },
    {
        id: '702',
        title: 'Mobile devices',
        query: 'Mobile',
    },
];

export const ADMIN_DEVICE_OS_LIST: IDropdownItem[] = [
    {
        id: '703',
        title: 'All devices OS',
        query: '',
    },
    {
        id: '704',
        title: 'Android devices',
        query: 'Android',
    },
    {
        id: '705',
        title: 'IOS devices',
        query: 'Ios',
    },
    {
        id: '706',
        title: 'Other devices',
        query: 'Other',
    },
];

export const REPORT_TYPES_FILTER_LIST: IDropdownItem[] = [
    {
        id: '800',
        title: 'All report types',
        query: '',
    },
    {
        id: '801',
        title: 'Privacy violation',
        query: 'Privacy violation',
    },
    {
        id: '802',
        title: 'Pornographic content',
        query: 'Pornographic content',
    },
    {
        id: '803',
        title: 'Abuse',
        query: 'Abuse',
    },
    {
        id: '804',
        title: 'Hate Speech',
        query: 'Hate Speech',
    },
    {
        id: '805',
        title: 'Extremist content',
        query: 'Extremist content',
    },
];

export const ADMIN_VERIF_REQUESTS_FILTERING_LIST: IDropdownItem[] = [
    {
        id: '900',
        title: 'All requests',
        query: '',
    },
    {
        id: '901',
        title: 'Accepted requests',
        query: 'ACCEPTED',
    },
    {
        id: '902',
        title: 'Declined requests',
        query: 'DECLINED',
    },
    {
        id: '903',
        title: 'Pending requests',
        query: 'PENDING',
    },
];

export const ADMIN_EVENTS_FILTER_LIST: IDropdownItem[] = [
    {
        id: '1001',
        title: 'All events',
        query: null,
    },
    {
        id: '1002',
        title: 'Public events',
        query: 'PUBLIC',
    },
    {
        id: '1003',
        title: 'Private events',
        query: 'PRIVATE',
    },
];

export const PAYMENT_INCOME_STATUS_FILTER_LIST: IDropdownItem[] = [
    {
        id: '1100',
        title: 'All statuses',
        query: null,
    },
    {
        id: '1101',
        title: 'Pending',
        query: 'PENDING',
    },
    {
        id: '1102',
        title: 'Approved',
        query: 'APPROVED',
    },
];

export const PAYMENT_OUTCOME_STATUS_FILTER_LIST: IDropdownItem[] = [
    {
        id: '1110',
        title: 'All statuses',
        query: null,
    },
    {
        id: '1111',
        title: 'White-off',
        query: 'APPROVED',
    },
    {
        id: '1112',
        title: 'Refunds',
        query: 'REFUND',
    },
];

export const PAYMENT_STATUS_ADMIN_ARTIST_FILTER_LIST: IDropdownItem[] = [
    ...PAYMENT_INCOME_STATUS_FILTER_LIST,
    ...PAYMENT_OUTCOME_STATUS_FILTER_LIST.slice(2),
];

export const CONTRACT_ARTIST_FILTER_LIST: IDropdownItem[] = [
    {
        id: '1410',
        title: 'All statuses',
        query: null,
    },
    {
        id: '1412',
        title: 'Pending',
        query: 'PENDING_AGENT',
    },
    {
        id: '1312',
        title: 'Accepted',
        query: 'ACCEPTED',
    },
    {
        id: '1313',
        title: 'Denied',
        query: 'DENIED',
    },
];

export const CONTRACT_AGENT_FILTER_LIST: IDropdownItem[] = [
    {
        id: '1310',
        title: 'All statuses',
        query: null,
    },
    {
        id: '1311',
        title: 'Pending',
        query: 'PENDING_ARTIST',
    },
    {
        id: '1312',
        title: 'Accepted',
        query: 'ACCEPTED',
    },
    {
        id: '1313',
        title: 'Denied',
        query: 'DENIED',
    },
];

export const PAYMENT_ALL_STATUS_FILTER_LIST: IDropdownItem[] = [
    ...PAYMENT_INCOME_STATUS_FILTER_LIST,
    {
        id: '1113',
        title: 'Needs attention',
        query: 'NEEDS_ATTENTION',
    },
];

export const PAYMENT_REASONS_FILTER_LIST: IDropdownItem[] = [
    {
        id: '1110',
        title: 'All reasons',
        query: null,
    },
    {
        id: '1111',
        title: 'Private event',
        query: 'PRIVATE_EVENT',
    },
    {
        id: '1112',
        title: 'Public event',
        query: 'PUBLIC_EVENT',
    },
    {
        id: '1113',
        title: 'Promotion',
        query: 'PROMOTION',
    },
    {
        id: '1114',
        title: 'Donation',
        query: 'DONATION',
    },
    {
        id: '1115',
        title: 'Common',
        query: 'COMMON',
    },
];
export const PAYMENT_REASONS_FILTER_LIST_ARTIST: IDropdownItem[] = [
    {
        id: '1110',
        title: 'All reasons',
        query: null,
    },
    {
        id: '1111',
        title: 'Private event',
        query: 'PRIVATE_EVENT',
    },
    {
        id: '1112',
        title: 'Public event',
        query: 'PUBLIC_EVENT',
    },
    {
        id: '1113',
        title: 'Promotion',
        query: 'PROMOTION',
    },
    {
        id: '1114',
        title: 'Commission',
        query: 'COMMISSION',
    },
    {
        id: '1115',
        title: 'Common',
        query: 'COMMON',
    },
];

export const PAYMENT_EVENTS_FILTER_LIST: IDropdownItem[] = [
    {
        id: '1001',
        title: 'All events',
        query: null,
    },
    {
        id: '1002',
        title: 'Public events',
        query: 'PUBLIC_EVENT',
    },
    {
        id: '1003',
        title: 'Private events',
        query: 'PRIVATE_EVENT',
    },
];

export const PROMOTIONS_STATUS: IDropdownItem[] = [
    {
        id: '1031',
        title: 'All statuses',
        query: null,
    },
    {
        id: '1032',
        title: 'Expired',
        query: 'CANCELLED',
    },
    {
        id: '1033',
        title: 'Active',
        query: 'NON_RENEWING',
    },
];

export const  FILTER_ADMIN_PAYMENTS: IDropdownItem[] = [
    {
        id: '1051',
        title: 'All payments',
        query: null,
    },
    {
        id: '1052',
        title: 'Income',
        query: 'INCOME',
    },
    {
        id: '1053',
        title: 'Expense',
        query: 'EXPENSE',
    },
];

export const PROMOTIONS_FILTER_TYPES: IDropdownItem[] = [
    {
        id: '1041',
        title: 'All types',
        query: null,
    },
    {
        id: '1042',
        title: 'Local (Local 1)',
        query: 'Local (Local 1)',
    },
    {
        id: '1043',
        title: 'Local (Local 2)',
        query: 'Local (Local 2)',
    },
    {
        id: '1044',
        title: 'Local (Local 3)',
        query: 'Local (Local 3)',
    },
    {
        id: '1045',
        title: 'International (Inter 1)',
        query: 'International (Inter 1)',
    },
    {
        id: '1046',
        title: 'International (Inter 2)',
        query: 'International (Inter 2)',
    },
    {
        id: '1047',
        title: 'International (Inter 3)',
        query: 'International (Inter 3)',
    },
    {
        id: '1048',
        title: 'Social',
        query: 'Social',
    },
];
export const PAYMENT_EVENTS_FILTER_LIST_ARTIST: IDropdownItem[] = [
    {
        id: '1001',
        title: 'All types',
        query: null,
    },
    {
        id: '1002',
        title: 'Public events',
        query: 'PUBLIC_EVENT',
    },
    {
        id: '1003',
        title: 'Private events',
        query: 'PRIVATE_EVENT',
    },
    {
        id: '1004',
        title: 'Donation',
        query: 'DONATION',
    },
    {
        id: '1005',
        title: 'Video',
        query: 'VIDEO',
    },
    {
        id: '1006',
        title: 'Merchandise',
        query: 'MERCHANDISE',
    },
];

export const PAYMENT_TYPE_ADMIN_ARTIST_FILTER_LIST: IDropdownItem[] = [
     ...PAYMENT_EVENTS_FILTER_LIST_ARTIST,
    ...PAYMENT_REASONS_FILTER_LIST.slice(1),
];
// radioitems

export const WITH_AGENT_ITEMS: IRadioItem[] = [
    { id: '1', label: 'I’m independent artist', query: false },
    { id: '2', label: 'I work with agent', query: true },
];

export const VIEWERS_RADIO_ITEMS: IRadioItem[] = [
    { id: '5', label: 'Unlimited', query: 'unlimited' },
    { id: '6', label: 'Custom', query: 'custom' },
];

export const GENDER_RADIO_ITEMS: IRadioItem[] = [
    { id: '7', label: 'Male', query: 'MALE' },
    { id: '8', label: 'Female', query: 'FEMALE' },
    { id: '9', label: 'LGBTQ community', query: 'LGBTQ' },
];

export const PAID_OR_FREE_RADIO_ITEMS: IRadioItem[] = [
    { id: '10', label: 'Free', query: false },
    { id: '11', label: 'Paid', query: true },
];

// categories
const CARD_PATH = '../../../../../assets/images/categories/';

export const ALL_CATEGORY_CARDS: ICategoryCard[] = [
    {
        title: 'Singers & Bands',
        src: `${CARD_PATH}singer`,
    },
    {
        title: `DJ's`,
        src: `${CARD_PATH}dj`,
    },
    {
        title: 'Dance',
        src: `${CARD_PATH}dance`,
    },
    {
        title: 'Cooking',
        src: `${CARD_PATH}cooking`,
    },
    {
        title: 'Comedy',
        src: `${CARD_PATH}comedy`,
    },
    {
        title: 'Personal training',
        src: `${CARD_PATH}training`,
    },
    {
        title: 'Education',
        src: `${CARD_PATH}education`,
    },
    {
        title: 'Lectures',
        src: `${CARD_PATH}lectures`,
    },
    {
        title: 'Children',
        src: `${CARD_PATH}children`,
    },
    {
        title: 'Live Entertainment',
        src: `${CARD_PATH}entertainment`,
    },
    {
        title: 'Theater',
        src: `${CARD_PATH}theatre`,
    },
    {
        title: 'Book Reading',
        src: `${CARD_PATH}reading`,
    },
    {
        title: 'Tour guide',
        src: `${CARD_PATH}guide`,
    },
];

export const languagesList: any = {
    languageEn: {
        id: 'languageEn',
        title: 'English',
        paramName: 'language',
        paramValue: 'en',
    },
    languageRu: {
        id: 'languageRu',
        title: 'Russian',
        paramName: 'language',
        paramValue: 'ru',
    },
    languagePt: {
        id: 'languagePt',
        title: 'Portuguese',
        paramName: 'language',
        paramValue: 'pt',
    },
    languageFr: {
        id: 'languageFr',
        title: 'French',
        paramName: 'language',
        paramValue: 'fr',
    },
    languageHe: {
        id: 'languageHe',
        title: 'Hebrew',
        paramName: 'language',
        paramValue: 'he',
    },
    languageGe: {
        id: 'languageGe',
        title: 'Germany',
        paramName: 'language',
        paramValue: 'de',
    },
    languageEs: {
        id: 'languageEs',
        title: 'Spanish',
        paramName: 'language',
        paramValue: 'es',
    },
    languageRo: {
        id: 'languageRo',
        title: 'Romanian',
        paramName: 'language',
        paramValue: 'ro',
    },
    languageFa: {
        id: 'languageFa',
        title: 'Persian',
        paramName: 'language',
        paramValue: 'fa',
    },
    languageTa: {
        id: 'languageTa',
        title: 'Tamil',
        paramName: 'language',
        paramValue: 'ta',
    },
    languageVi: {
        id: 'languageVi',
        title: 'Vietnamese',
        paramName: 'language',
        paramValue: 'vi',
    },
    languageBn: {
        id: 'languageBn',
        title: 'Bengali',
        paramName: 'language',
        paramValue: 'bn',
    },
    languageHi: {
        id: 'languageHi',
        title: 'Hindi',
        paramName: 'language',
        paramValue: 'hi',
    },
    languagePl: {
        id: 'languagePl',
        title: 'Polish',
        paramName: 'language',
        paramValue: 'pl',
    },
    languageKo: {
        id: 'languageKo',
        title: 'Korean',
        paramName: 'language',
        paramValue: 'ko',
    },
    languageTr: {
        id: 'languageTr',
        title: 'Turkish',
        paramName: 'language',
        paramValue: 'tr',
    },
    languageJa: {
        id: 'languageJa',
        title: 'Japanese',
        paramName: 'language',
        paramValue: 'ja',
    },
    languageIt: {
        id: 'languageIt',
        title: 'Italian',
        paramName: 'language',
        paramValue: 'it',
    },
    languageAr: {
        id: 'languageAr',
        title: 'Arab',
        paramName: 'language',
        paramValue: 'ar',
    },
    languageZh: {
        id: 'languageZh',
        title: 'Chinese',
        paramName: 'language',
        paramValue: 'zh',
    },
};

export const artistFilterList: any = {
    isOnline: {
        id: 'isOnline',
        title: 'Online',
        paramName: 'isOnline',
        paramValue: true,
    },
    agentId: {
        id: 'agentId',
        title: 'Collab',
        paramName: 'agentId',
        paramValue: '',
    },
    hasPrivateEvents: {
        id: 'hasPrivateEvents',
        title: 'Private Events',
        paramName: 'hasPrivateEvents',
        paramValue: true,
    },
    hasPublicEvents: {
        id: 'hasPublicEvents',
        title: 'Public Events',
        paramName: 'hasPublicEvents',
        paramValue: true,
    },
    languageZh: {
        id: 'languageZh',
        title: 'Chinese',
        paramName: 'language',
        paramValue: 'zh',
    },
    genderMale: {
        id: 'genderMale',
        title: 'Male',
        paramName: 'genders',
        paramValue: 'MALE',
    },
    genderFemale: {
        id: 'genderFemale',
        title: 'Female',
        paramName: 'genders',
        paramValue: 'FEMALE',
    },
    genderLGBTQ: {
        id: 'genderLGBTQ',
        title: 'LGBTQ',
        paramName: 'genders',
        paramValue: 'LGBTQ',
    },
    priceFrom: {
        id: 'priceFrom',
        title: 'Price from',
        paramName: 'priceFrom',
        paramValue: 0,
    },
    priceTo: {
        id: 'priceTo',
        title: 'Price to',
        paramName: 'priceTo',
        paramValue: 0,
    },
    free: {
        id: 'free',
        title: 'Free',
        paramName: 'type',
        paramValue: 'FREE',
    },
    hasPaid: {
        id: 'hasPaid',
        title: 'Paid',
        paramName: 'type',
        paramValue: 'PAID',
    },
    ...languagesList,
};

export const agentsFilterList: any = {
    isOnline: {
        id: 'isOnline',
        title: 'Online',
        paramName: 'isOnline',
        paramValue: true,
    },
    agentId: {
        id: 'agentId',
        title: 'Collab',
        paramName: 'agentId',
        paramValue: '',
    },
    hasPrivateEvents: {
        id: 'hasPrivateEvents',
        title: 'Private Events',
        paramName: 'hasPrivateEvents',
        paramValue: true,
    },
    hasPublicEvents: {
        id: 'hasPublicEvents',
        title: 'Public Events',
        paramName: 'hasPublicEvents',
        paramValue: true,
    },
    genderMale: {
        id: 'genderMale',
        title: 'Male',
        paramName: 'genders',
        paramValue: 'MALE',
    },
    genderFemale: {
        id: 'genderFemale',
        title: 'Female',
        paramName: 'genders',
        paramValue: 'FEMALE',
    },
    genderLGBTQ: {
        id: 'genderLGBTQ',
        title: 'LGBTQ',
        paramName: 'genders',
        paramValue: 'LGBTQ',
    },
    priceFrom: {
        id: 'priceFrom',
        title: 'Price from',
        paramName: 'priceFrom',
        paramValue: 0,
    },
    priceTo: {
        id: 'priceTo',
        title: 'Price to',
        paramName: 'priceTo',
        paramValue: 0,
    },
    free: {
        id: 'free',
        title: 'Free',
        paramName: 'type',
        paramValue: 'FREE',
    },
    hasPaid: {
        id: 'hasPaid',
        title: 'Paid',
        paramName: 'type',
        paramValue: 'PAID',
    },
    ...languagesList,
};

